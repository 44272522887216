import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: true,
  authenticationConfig: {
    clientID: 'GoE2Kq65MZYw154OIA1iWca6LmlY3Jkw',
    domain: 'auth.frascheri.com',
    redirectUri: 'https://ordini.frascheri.it/',
    responseType: 'token id_token',
    scope: 'openid profile'
  },
  graphqlUri: 'ordini-api.frascheriprofessionale.it/v1alpha1/graphql',
  storageConfig: {
    commonAssetsUrl:
      'https://frascheri.fra1.digitaloceanspaces.com/common/',
    productsDataSheetsUrl: 'https://frascheri.fra1.digitaloceanspaces.com/products-datasheets/',
    // productsDataSheetsUrl:
    //   'https://sitoprostorageaccount.blob.core.windows.net/products-datasheets/',
    productsImagesSmallUrl:
      'https://frascheri.fra1.digitaloceanspaces.com/products-images-small/',
    productsImagesMediumUrl:
      'https://frascheri.fra1.digitaloceanspaces.com/products-images-medium/',
    productsImagesLargeUrl:
      'https://frascheri.fra1.digitaloceanspaces.com/products-images-large/'
  },
  GA_ID: 'UA-16149300-10'
};
